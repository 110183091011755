import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { getQuestions } from './startApi';

export interface testState {
    titleTest: string;
    questions: Array<any>;
    email: string;
    score: number;
    show_correct: boolean;
    // status: 'idle' | 'loading' | 'failed';
    user_answers: Array<number>;
    tests: Array<any>;
    current_test_id: number;
    tests_done: Array<any>;
}

const initialState: testState = {
    titleTest: '',
    questions: [],
    email: '',
    score: 0,
    show_correct: false,
    user_answers: [],
    tests: [],
    tests_done: [],
    current_test_id: 0,
};



export const startSlice = createSlice({
    name: 'start',
    initialState,
    reducers: {
        setTestsDone: (state, action: PayloadAction<Array<any>>) => {
            state.tests_done = action.payload;
        },
        setTests: (state, action: PayloadAction<Array<any>>) => {
            state.tests = action.payload;
        },
        setUserAnswers: (state, action: PayloadAction<Array<number>>) => {
            state.user_answers = action.payload;
        },
        setShowCorrect: (state, action: PayloadAction<boolean>) => {
            state.show_correct = action.payload;
        },
        setScore: (state, action: PayloadAction<number>) => {
            state.score = action.payload;
        },
        setTitleTest: (state, action: PayloadAction<string>) => {
            state.titleTest = action.payload;
        },
        setQuestions: (state, action: PayloadAction<Array<any>>) => {
            state.questions = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setCurrentTestId: (state, action: PayloadAction<number>) => {
            state.current_test_id = action.payload;
        },
    },
});

export const {setTestsDone, setCurrentTestId, setTests, setUserAnswers, setShowCorrect, setScore, setEmail, setTitleTest, setQuestions } = startSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTitleTest = (state: RootState) => state.start.titleTest;
export const selectQuestions = (state: RootState) => state.start.questions;
export const selectEmail = (state: RootState) => state.start.email;
export const selectScore = (state: RootState) => state.start.score;
export const selectShowCorrect = (state: RootState) => state.start.show_correct;
export const selectUserAnswers = (state: RootState) => state.start.user_answers;
export const selectTests = (state: RootState) => state.start.tests;
export const selectCurrentTestId = (state: RootState) => state.start.current_test_id;
export const selectTestsDone = (state: RootState) => state.start.tests_done;

export const getApiQuestions = (test_id: number): AppThunk => (dispatch, getState) => {
    //console.log('getApiQuestions', test_id)

    getQuestions({ test_id: test_id }, (data) => {
        //console.log('getQuestions', data)
        dispatch(setQuestions(data.questions))
        dispatch(setUserAnswers(Array(data.questions.length).fill(0)))
    })
};



export default startSlice.reducer;
