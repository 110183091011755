import React, { MouseEventHandler } from 'react';
import './button.css'

import { svg_double_check } from '../../static/svg/svg';

export function Button(props: {style_button: string, text: string, onClick: MouseEventHandler, is_arrow: Boolean}) {

  return (
    <div className={props.style_button} onClick={props.onClick} style={{cursor: 'pointer'}}>
        <div>{props.text}</div>
        {
          props.is_arrow && 
          <div style={{marginLeft: '10px'}}>{svg_double_check}</div>
          
        }
    </div>
  );
}
