/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import styles from '../Test/Test.module.css';
import { useNavigate } from 'react-router-dom';
import { getTestInfo, getTests, sendAnswers } from '../startTest/startApi';

import man_music from '../../static/img/man_music.png'
import smile_0 from '../../static/img/smiles/smile0.png'
import smile_game from '../../static/img/smiles/smile_game.png'
import smile_smile from '../../static/img/smiles/smile_smile.png'
import smile_star_y from '../../static/img/smiles/smile_star_y.png'

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getApiQuestions, selectCurrentTestId, selectEmail, selectTests, selectTitleTest, setCurrentTestId, setShowCorrect, setTests, setTitleTest } from '../startTest/startSlice';
import { ResultBlock } from './resultBlock';
import { svg_bg } from '../../static/svg/svg';
import { ShareBlock } from './shareBlock';
import { signs, testGoals } from '../../static/vars/vars';

import img_smile_think from '../../static/img/smilethink.png'
import { Button } from '../Common/button';
import { useSelector } from 'react-redux';

export function Info() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const email = useAppSelector(selectEmail)
    const titleTest = useAppSelector(selectTitleTest)
    const tests = useSelector(selectTests)

    const current_test_id = useAppSelector(selectCurrentTestId)

    const handleClickCorrectAnswers = () => {
        dispatch(setShowCorrect(true))
        navigate('/test', { replace: true })
    }

    const handleGetTestInfo = (test_id: number) => {
        // console.log('handleGetTestInfo')
        getTestInfo({ test_id: test_id }, (data) => {
            // console.log('getTestInfo', data)
            dispatch(setTitleTest(data.test_info[0].name))
        })
    }

    const handleGetTests = () => {
        getTests((data: { tests: { id: number; /* другие поля теста */ }[] }) => {
            // Получаем значение из локального хранилища
            const localStorageValue = localStorage.getItem('tests18');
            const completedTestIds = localStorageValue ? localStorageValue.split('-').map(Number) : [];

            // Обновляем тесты в зависимости от пройденных тестов
            const updatedTests = data.tests.map(test => {
                const isDone = completedTestIds.includes(test.id);
                return { ...test, is_done: isDone };
            });

            dispatch(setTests(updatedTests));
        });
    };

    function handleClickTest(test_id: number) {
        handleGetTests()
        handleGetTestInfo(test_id)

        if (testGoals[test_id]) {
            window.ym(93471245, 'reachGoal', `start_${testGoals[test_id]}`);
        }
        else if (test_id === 1)window.ym(93471245, 'reachGoal', 'start')
        // console.log(9)
        dispatch(setCurrentTestId(test_id))
        dispatch(getApiQuestions(test_id))
        navigate('/test', { replace: true })
    }

    return (
        <>
            <div className={styles.container1}>
                <div style={{ position: 'absolute', top: '-50px', left: 0, height: '100%', zIndex: 0 }}>
                    {svg_bg}
                </div>
                <div className={styles.container2}>
                    <div className={styles.title}>{titleTest}</div>

                    <div className={styles.cntrRowBetween}>
                        <div className={styles.col1}>
                            <img className='img__col1' src={man_music} alt='' />
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.header_col2_info}>
                                <div className='cntr-flex a-c' onClick={handleClickCorrectAnswers}>
                                    <div className={styles.arrow_container}

                                    >
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 11.8369L3.20605 7L8 2.16315L6.39698 0.5L0 7L6.39698 13.5L8 11.8369Z" fill="#326BFF" />
                                        </svg>
                                    </div>

                                    <div className='text-5 color-blue ml-16' >Посмотреть верные ответы</div>
                                </div>

                                <div className='text-5 color-blue cur-p' onClick={() => navigate('/', { replace: true })}>На главную</div>

                            </div>
                            <div className='text-4 mt-20 mob__txt_a_c'>Ваш результат отправлен на &nbsp;<span className='desktop' style={{ color: '#326BFF' }}>{email}</span></div>
                            <div className='text-4 mobile mob__txt_a_c' style={{ color: '#326BFF' }}>{email}</div>
                            <ResultBlock />
                            <ShareBlock />

                        </div>
                    </div>
                </div>
            </div>
            <div className='cntr_select_test'>
                {
                    tests.map((test: { id: number, name: string, description: string, is_done: boolean }, index: number) => {
                        return (
                            <div className='cntr_slaid' style={{ display: (test.id === current_test_id || test.is_done) ? 'none' : 'flex' }}>
                                <div className={styles.slaid_title}>
                                    <div className={styles.slaid_title_text}>{signs[index]}{test.name}</div>
                                    <div className={styles.slaid_title_2}>


                                        <div className={styles.slaid_title_text_mini_r}>Не пройден</div>
                                        <img className={styles.img__smile_think} src={img_smile_think} alt='' />

                                    </div>
                                </div>

                                <div className={styles.slaid_text}>
                                    {test.description}
                                </div>

                                <div style={{ fontSize: '10px', display: 'flex', width: '100%', marginLeft: '0px', flexWrap: 'wrap' }}>
                                    <div className={index === 0 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 0 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Общие вопросы
                                        </div>
                                    </div>
                                    <div className={index === 4 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 4 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Секс
                                        </div>
                                    </div>
                                    <div className={index === 3 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 3 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Закон
                                        </div>
                                    </div>
                                    <div className={index === 4 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 4 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Политика
                                        </div>
                                    </div>
                                    <div className={index === 2 || index === 3 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 2 || index === 3 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Тусовки
                                        </div>
                                    </div>
                                    <div className={index === 1 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                        <div className={index === 1 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                            Деньги
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.cntr__btn}>
                                    <Button
                                        style_button={'bg-blue j-c btn-container btn-text'}
                                        text={'Пройти тест'}
                                        onClick={() => handleClickTest(test.id)}
                                        is_arrow={true}
                                    />
                                    {/* <div className={styles.cntr__double_check}>
                                    {svg_double_check}
                                </div> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
}
