import React, { MouseEventHandler } from 'react';
import styles from './Start.module.css';

import { Button } from '../Common/button';

import img_smile_think from '../../static/img/smilethink.png'
import smile_star_y from '../../static/img/smiles/smile_star_y.png'
import { svg_double_check } from '../../static/svg/svg';

export function StartSlaid(props: { handleClickTest: MouseEventHandler, title_text: String, slaid_text: String, disable: boolean, is_done: boolean }) {

    return (
        <div className={styles.cntr_slaid}>
            <div className={styles.slaid_title}>
                <div className={styles.slaid_title_text}>{props.title_text}</div>
                <div className={styles.slaid_title_2}>
                    {props.is_done ?
                        <>
                            <div className={styles.slaid_title_text_mini}>Пройден</div>
                            <img className={styles.img__smile_think} src={smile_star_y} alt='' />
                        </>:
                        <>
                            <div className={styles.slaid_title_text_mini}>Не пройден</div>
                            <img className={styles.img__smile_think} src={img_smile_think} alt='' />
                        </>
                    }
                </div>
            </div>

            <div className={styles.slaid_text}>
                {props.slaid_text}
            </div>

            <div style={{ display: 'flex', width: '100%', marginLeft: '-8px', flexWrap: 'wrap' }}>
                <div className={styles.cntr_name_subject}>
                    <div className={styles.name_subject_text}>
                        Общие вопросы
                    </div>
                </div>
                <div className={styles.cntr_name_subject_no}>
                    <div className={styles.name_subject_text_grey}>
                        Секс
                    </div>
                </div>
                <div className={styles.cntr_name_subject_no}>
                    <div className={styles.name_subject_text_grey}>
                        Закон
                    </div>
                </div>
                <div className={styles.cntr_name_subject_no}>
                    <div className={styles.name_subject_text_grey}>
                        Политика
                    </div>
                </div>
                <div className={styles.cntr_name_subject_no}>
                    <div className={styles.name_subject_text_grey}>
                        Тусовки
                    </div>
                </div>
                <div className={styles.cntr_name_subject_no}>
                    <div className={styles.name_subject_text_grey}>
                        Деньги
                    </div>
                </div>

            </div>

            <div className={styles.cntr__btn}>
                <Button
                    style_button={props.disable ? 'bg-grey j-c btn-container btn-text_dis' : 'bg-blue j-c btn-container btn-text'}
                    text={props.disable ? 'Необходимо пройти базовый тест' : 'Пройти тест'}
                    onClick={props.handleClickTest}
                    is_arrow={!props.disable}
                />
                {/* <div className={styles.cntr__double_check}>
                                            {svg_double_check}
                                        </div> */}
            </div>
        </div>
    );
}
