import { URL } from "../../static/vars/vars";

export function getTestInfo(pars: { test_id: number }, callback: (data: any) => void) {
    //console.log('pars', pars)
    fetch(URL + '/gettestinfo', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // mode: 'no-cors',
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            //console.log('data', data)
            return callback(data)
        });
}

export function getTests( callback: (data: any) => void ) {
    //console.log('pars')
    fetch(URL + '/gettests', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // mode: 'no-cors',
        body: JSON.stringify({})
    })
        .then((response) => response.json())
        .then((data) => {
            //console.log('gettests data', data)
            return callback(data)
        });
}


export function getQuestions(pars: { test_id: number }, callback: (data: any) => void) {
    //console.log('pars', pars)
    fetch(URL + '/getquestions', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // mode: 'no-cors',
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            //console.log('data', data)
            return callback(data)
        });
}

export function sendAnswers(pars: { title_test: string, test_id: number, email: string, score: number }, callback: (data: any) => void) {
    //console.log('pars', pars)
    fetch(URL + '/sendanswers', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // mode: 'no-cors',
        body: JSON.stringify(pars)
    })
        .then((response) => response.json())
        .then((data) => {
            //console.log('data', data)
            return callback(data)
        });
}