import React from 'react';

import smile_0 from '../../static/img/smiles/smile0.png'
import smile_1 from '../../static/img/smiles/smile1.png'
import smile_game from '../../static/img/smiles/smile_game.png'
import smile_game_1 from '../../static/img/smiles/smile_game_1.png'
import smile_smile from '../../static/img/smiles/smile_smile.png'
import smile_smile_1 from '../../static/img/smiles/smile_smile_1.png'
import smile_star from '../../static/img/smiles/smile_star.png'
import smile_star_y from '../../static/img/smiles/smile_star_y.png'
import { useAppSelector } from '../../app/hooks';
import { selectScore } from '../startTest/startSlice';


const result_texts = [
    `Взрослая жизнь тебя не пугает и ты на отлично
    ориентируешься в ней.
    Тебя ждет столько возможностей, но и про обязанности не
    стоит забывать. Удачи в самостоятельном плавании!`,
    `Всегда есть, к чему стремиться, поэтому будь уверен в своих силах и узнавай много нового. 
    Взрослая жизнь не только дает новые возможности, но и накладывает некоторые обязанности.`,
    `Советуем немножко больше узнать о том, что ждет тебя после достижения 18 лет. 
    Столько возможностей откроется перед тобой, ими стоит воспользоваться! `,
    `Понимаем, так не хочется окунать в проблемы взрослых, но так много интересного и захватывающего ждет тебя. 
    У тебя все получится в самостоятельном плавании!`
]

const result_titles = [
    'Ты готов к совершеннолетию на все 100%! 🤩',
    'Ты готов к совершеннолетию на все 80%! 😉',
    'Ты готов к совершеннолетию на 50%! 😉',
    'Ты готов к совершеннолетию на все 20%! 😉'
]

export function ResultBlock() {
    const score = useAppSelector(selectScore)

    return (
        <div className='cntr-flex j-c'>
            <div className='container2 mt-40'>
                <div className='cntr-flex j-b a-c mx-75'>
                    <img style={score < 3 ? {width: '63px', height: '62px'}: { width: '38px', height: '37px' }} src={score < 3 ? smile_1: smile_0} alt='' />
                    <img style={3 <= score && score < 5 ? {width: '63px', height: '62px'}: { width: '38px', height: '37px' }} src={3 <= score && score < 5 ? smile_game_1: smile_game} alt='' />
                    <img style={5 <= score && score < 7 ? {width: '63px', height: '62px'}: { width: '38px', height: '37px' }} src={5 <= score && score < 7 ? smile_smile_1: smile_smile} alt='' />
                    <img style={7 <= score ? {width: '63px', height: '62px'}: { width: '38px', height: '37px' }} src={7 <= score ? smile_star_y: smile_star} alt='' />
                </div>
                <div className='text-4'>
                    {
                        score > 7 ? result_titles[0]:
                        score > 5 ? result_titles[1]:
                        score > 3 ? result_titles[2]:
                        result_titles[3]
                    }
                </div>

                <div className='text-5 mt-20'>
                    {
                        score > 7 ? result_texts[0]:
                        score > 5 ? result_texts[1]:
                        score > 3 ? result_texts[2]:
                        result_texts[3]
                    }
                </div>
            </div>
        </div>
    );
}
