export const URL = 'https://test18plus.na4u.ru'

export const signs = ['👀', '💳', '✅', '🤑', '🍌', '🏠', '⏲️', '🏠', '🏠', '🏠', '🏠']


export const testGoals: { [key: number]: string } = {
    2: 'money',
    3: 'pop',
    4: 'party',
    5: 'sex',
    7: 'blog',
    8: 'job',
    9: 'budget',
    10: 'med',
    11: 'sigs',
    12: 'auto'
  };