/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Start.module.css';
import { Button } from '../Common/button';
import { StartSlaid } from './startSlaid';
import { getTestInfo, getTests } from './startApi';
import { useDispatch, useSelector } from 'react-redux';
import { getApiQuestions, selectTests, selectTestsDone, selectTitleTest, setCurrentTestId, setEmail, setTests, setTestsDone, setTitleTest } from './startSlice';
import { useAppDispatch } from '../../app/hooks';

import img_man_think from '../../static/img/man_think2.png'
import img_man_think_desc from '../../static/img/man_think.png'
import img_smile_think from '../../static/img/smilethink.png'
import smile_star from '../../static/img/smiles/smile_star.png'
import smile_star_y from '../../static/img/smiles/smile_star_y.png'
import { svg_bg, svg_double_check, svg_sinuous_arrow } from '../../static/svg/svg';
import { testGoals } from '../../static/vars/vars';

declare global {
    interface Window {
        ym: any;
    }
}

const signs = ['👀', '💳', '✅', '🤑', '🍌', '🏠', '⏲️', '🏠', '🏠', '🏠', '🏠']
export function Start() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const titleTest = useSelector(selectTitleTest)
    const tests = useSelector(selectTests)

    const [baseTestDone, setBaseTestDone] = useState(false);
    const testsDone = useSelector(selectTestsDone);

    const handleGetTestInfo = (test_id: number) => {
        //console.log('handleGetTestInfo')
        getTestInfo({ test_id: test_id }, (data) => {
            //console.log('getTestInfo', data)
            dispatch(setTitleTest(data.test_info[test_id - 1].name))
        })
    }

    function handleClickTest(test_id: number) {
        handleGetTestInfo(test_id)
        if (testGoals[test_id]) {
            window.ym(93471245, 'reachGoal', `start_${testGoals[test_id]}`);
        }
        else if (test_id === 1) window.ym(93471245, 'reachGoal', 'start')
        //console.log(9)
        dispatch(setCurrentTestId(test_id))
        dispatch(getApiQuestions(test_id))
        navigate('/test', { replace: true })
    }

    const handleClickRight = () => {
        //console.log('r')
        let cntr = document.getElementById('cntr_slaids')
        cntr?.classList.add("slaid_anim");
        // cntr?.className  = "slaid_anim"
        //console.log(parseInt(cntr?.style.marginLeft.slice(0, -2) || '0'))
        let mg = parseInt(cntr?.style.marginLeft.slice(0, -2) || '0')
        mg = mg + 23
        setTimeout(() => {
            if (cntr) cntr.style.marginLeft = mg.toString() + "vw";
            cntr?.classList.remove("slaid_anim");
        }, 1000)

    }

    const handleClickLeft = () => {
        //console.log('l')
        let cntr = document.getElementById('cntr_slaids')
        cntr?.classList.add("slaid_anim_l");

        //console.log(parseInt(cntr?.style.marginLeft.slice(0, -2) || '0'))
        let mg = parseInt(cntr?.style.marginLeft.slice(0, -2) || '0')
        mg = mg - 23
        setTimeout(() => {
            if (cntr) cntr.style.marginLeft = mg.toString() + "vw";
            cntr?.classList.remove("slaid_anim_l");
        }, 1000)
    }

    const handleGetTests = () => {
        getTests((data: { tests: { id: number; /* другие поля теста */ }[] }) => {
            // Получаем значение из локального хранилища
            const localStorageValue = localStorage.getItem('tests18');
            const completedTestIds = localStorageValue ? localStorageValue.split('-').map(Number) : [];

            // Обновляем тесты в зависимости от пройденных тестов
            const updatedTests = data.tests.map(test => {
                const isDone = completedTestIds.includes(test.id);
                return { ...test, is_done: isDone };
            });

            dispatch(setTests(updatedTests));
        });
    };


    useEffect(() => {
        var myCarousel = document.getElementById('myCarousel')
        if (myCarousel) myCarousel.addEventListener('slide.bs.carousel', function (e) {
            const slaid = document.getElementById('next_slaid')
            if (slaid) {
                slaid.style.display = 'none'
                setTimeout(() => {
                    slaid.style.display = 'block'
                }, 500)
            }
        })
    }, []);

    useEffect(() => {
        handleGetTestInfo(1)
        handleGetTests()
        const done = localStorage.getItem("test18");
        //console.log('localStorage', done)
        if (done === '1') setBaseTestDone(true)

        const tests_done = localStorage.getItem("tests18");
        //console.log('tests18', tests_done)
        let arr_test_done: string[] = []
        if (tests_done) arr_test_done = tests_done.split('-')
        dispatch(setTestsDone(arr_test_done))

        const email = localStorage.getItem("email");
        if (email) dispatch(setEmail(email))



    }, []);
    return (
        <div className={styles.container1}>
            <div className='svg-bg' style={{ position: 'absolute', top: '-50px', left: 0, overflow: 'hidden', height: '100vh' }}>
                {svg_bg}
            </div>
            <div className={styles.container2}>
                <div className={styles.cntr_title}>
                    <div className={styles.title}>{titleTest}🔥</div>
                </div>
                <div className='text' style={{ marginTop: '34px' }}>
                    Узнайте, насколько вы готовы к совершеннолетию!<br></br>
                    Короткий тест покажет, что вы знаете о взрослой жизни, которую скоро (или почти скоро) вы начнете.
                </div>

                {/* MOBILE */}
                <div className='mobile' style={{ position: 'relative' }}>

                    <div className={styles.svg__sinuous_arrow}>
                        {svg_sinuous_arrow}
                    </div>
                </div>
                <img className={styles.img__man_think} src={img_man_think} alt='' />



                <img className={styles.img__man_think_desc} src={img_man_think_desc} alt='' />


                <div className='mobile' style={{ zIndex: '10', position: 'absolute', bottom: '60px', left: 0, width: '100%' }}>

                    <div id="myCarousel" className="carousel slide " data-bs-ride="carousel" data-bs-interval="false" data-bs-touch="true">

                        <div className="carousel-inner position-relative">
                            <div id='next_slaid' className={styles.next_slaid}>
                                <StartSlaid
                                    handleClickTest={() => handleClickTest(1)}
                                    title_text='👀 Базовый тест'
                                    slaid_text='Базовые вопросы, на разные темы, после прохождения откроется доступ к тематическим тестам'
                                    disable={false}
                                    is_done={false}
                                />
                            </div>

                            {/* <div className="carousel-item active" data-bs-interval="2000">
                                <StartSlaid
                                    handleClickTest={() => handleClickTest(1)}
                                    title_text='👀 Базовый тест'
                                    slaid_text='Базовые вопросы, на разные темы, после прохождения откроется доступ к тематическим тестам'
                                    disable={false}
                                />
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">

                                <StartSlaid
                                    handleClickTest={() => handleClickTest(2)}
                                    title_text='🫦 Секс и отношения '
                                    slaid_text='Вопросы о половой жизни, отношениях и жизни современной молодежи '
                                    disable={false}
                                />
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">

                                <StartSlaid
                                    handleClickTest={() => handleClickTest(3)}
                                    title_text='✅ Закон и политика'
                                    slaid_text='Базовые вопросы, на разные темы, после прохождения откроется доступ к тематическим тестам'
                                    disable={false}
                                />
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">

                                <StartSlaid
                                    handleClickTest={() => handleClickTest(4)}
                                    title_text='💳 Деньги'
                                    slaid_text='Базовые вопросы, на разные темы, после прохождения откроется доступ к тематическим тестам'
                                    disable={false}
                                />
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">

                                <StartSlaid
                                    handleClickTest={() => handleClickTest(4)}
                                    title_text='❤️ Секс'
                                    slaid_text='Секс — вот, что по-настоящему сделает тебя взрослым. Многие мечтают об этом всю школу, но чтобы им заниматься нужно понимать, какие виды секса бывают. Давай разберемся в них 💪'
                                    disable={false}
                                />
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">

                                <StartSlaid
                                    handleClickTest={() => handleClickTest(4)}
                                    title_text='❤️ Секс'
                                    slaid_text='Секс — вот, что по-настоящему сделает тебя взрослым. Многие мечтают об этом всю школу, но чтобы им заниматься нужно понимать, какие виды секса бывают. Давай разберемся в них 💪'
                                    disable={false}
                                />
                            </div> */}

                            {
                                tests.map((test: { id: number, name: string, description: string }, index: number) => {
                                    return (
                                        <div className={"carousel-item" + (index === 0 ? ' active' : '')} data-bs-interval="2000">

                                            <StartSlaid
                                                handleClickTest={() => handleClickTest(test.id)}
                                                title_text={signs[index] + test.name}
                                                slaid_text={test.description}
                                                disable={false}
                                                is_done={testsDone.includes(test.id.toString())}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                </div>


                {/* DESCTOP */}

                <div className={styles.cntr_circle_row}>
                    <div className={styles.circle_arrow} onClick={handleClickRight}>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11.8369L3.20605 7L8 2.16315L6.39698 0.5L0 7L6.39698 13.5L8 11.8369Z" fill="#326BFF" />
                        </svg>
                    </div>
                    <div style={{ marginLeft: '14px' }} className={styles.circle_arrow} onClick={handleClickLeft}>
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11.8369L4.79395 7L0 2.16315L1.60302 0.5L8 7L1.60302 13.5L0 11.8369Z" fill="#326BFF" />
                        </svg>
                    </div>
                </div>


                <div style={{ width: '100%', overflow: 'hidden' }} className='desctop'>
                    <div id="cntr_slaids">
                        <div className={styles.cntr_slaids}>
                            {
                                tests.map((test: { id: number, name: string, description: string }, index: number) => {
                                    return (
                                        <div className={styles.cntr_slaid}>
                                            <div className={styles.slaid_title}>
                                                <div className={styles.slaid_title_text}>{signs[index]}{test.name}</div>
                                                <div className={styles.slaid_title_2}>

                                                    {testsDone.includes(test.id.toString()) ?
                                                        <>
                                                            <div className={styles.slaid_title_text_mini}>Пройден</div>
                                                            <img className={styles.img__smile_think} src={smile_star_y} alt='' />
                                                        </>
                                                        :
                                                        <>
                                                            <div className={styles.slaid_title_text_mini_r}>Не пройден</div>
                                                            <img className={styles.img__smile_think} src={img_smile_think} alt='' />
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className={styles.slaid_text}>
                                                {test.description}
                                            </div>

                                            <div style={{ display: 'flex', width: '100%', marginLeft: '-8px', flexWrap: 'wrap' }}>
                                                <div className={index === 0 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 0 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Общие вопросы
                                                    </div>
                                                </div>
                                                <div className={index === 4 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 4 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Секс
                                                    </div>
                                                </div>
                                                <div className={index === 3 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 3 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Закон
                                                    </div>
                                                </div>
                                                <div className={index === 4 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 4 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Политика
                                                    </div>
                                                </div>
                                                <div className={index === 2 || index === 3 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 2 || index === 3 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Тусовки
                                                    </div>
                                                </div>
                                                <div className={index === 1 ? styles.cntr_name_subject : styles.cntr_name_subject_no}>
                                                    <div className={index === 1 ? styles.name_subject_text : styles.name_subject_text_grey}>
                                                        Деньги
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={styles.cntr__btn}>
                                                <Button
                                                    style_button={!baseTestDone && index !== 0 ? 'bg-grey j-c btn-container btn-text_dis' : 'bg-blue j-c btn-container btn-text'}
                                                    text={!baseTestDone && index !== 0 ? 'Необходимо пройти базовый тест' : 'Пройти тест'}
                                                    onClick={() => { if (baseTestDone || index === 0) handleClickTest(test.id) }}
                                                    is_arrow={true}
                                                />
                                                {/* <div className={styles.cntr__double_check}>
                                            {svg_double_check}
                                        </div> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }



                        </div>

                    </div>
                </div>



            </div>

            <div className={styles.term_of_use}></div>

            <a target='_blank' href={'https://checku.online/term'} className={styles.term_of_use} rel="noreferrer">
                Пользовательское соглашение
            </a>
            <a target='_blank' href={'https://checku.online/politic'} className={styles.politic} rel="noreferrer">
                Политика конфеденциальности
            </a>
        </div>
    );
}
