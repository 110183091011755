/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import styles from '../Test/Test.module.css';
import { useNavigate } from 'react-router-dom';
import { sendAnswers } from '../startTest/startApi';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCurrentTestId, selectEmail, selectScore, selectTitleTest, setEmail } from '../startTest/startSlice';

import img_think_man from '../../static/img/man_think.png'
import { svg_bg } from '../../static/svg/svg';
import { ResultBlock } from '../Info/resultBlock';
import { testGoals } from '../../static/vars/vars';

export function SendTest() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [inputEmail, setInputEmail] = useState('');

    
    const email = useAppSelector(selectEmail)
    const titleTest = useAppSelector(selectTitleTest)
    const score = useAppSelector(selectScore)
    const current_test_id = useAppSelector(selectCurrentTestId)
    

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSendTest = () => {
        if (validateEmail(inputEmail) || email) {

            localStorage.setItem('email', email)

            // window.ym(93471245, 'reachGoal', 'mail')
            // window.ym(93471245,'reachGoal','fin_money')
            if (current_test_id === 1) window.ym(93471245, 'reachGoal', `mail`);
            else if (testGoals[current_test_id]) {
                window.ym(93471245, 'reachGoal', `fin_${testGoals[current_test_id]}`);
            }

            let list_tests_done = localStorage.getItem('tests18')
            if (!list_tests_done) {
                list_tests_done = current_test_id.toString()
                localStorage.setItem('tests18', list_tests_done)
            }
            else {
                const arr = list_tests_done.split('-')
                if (!arr.includes(current_test_id.toString())) {
                    arr.push(current_test_id.toString())
                    localStorage.setItem('tests18', arr.join('-'))
                }
            }

            localStorage.setItem('test18', '1')
            sendAnswers({ title_test: titleTest, test_id: current_test_id, email: email || inputEmail, score: score }, (data) => {
                console.log('sendAnswers', data)
                navigate('/info', { replace: true })
                if (!email) dispatch(setEmail(inputEmail))
            })
        }
        else {
            alert('Неверный email')
        }
    }


    return (
        <div className={styles.container1}>
            <div style={{ position: 'absolute', top: '-50px', left: 0, height: '100%', zIndex: 0 }}>
                {svg_bg}
            </div>
            <div className={styles.container2}>
                <div className={styles.title}>{titleTest}</div>

                <div className={styles.cntrRowBetween}>
                    <div className={styles.col1}>
                        <img className='img__col1_send' src={img_think_man} alt='' />
                    </div>
                    <div className={styles.col2}>
                        {!email && <>
                            <div className={styles.send__test_text}>Оставь свою почту и узнай результат</div>
                            <div className='cntr__row mt-10' style={{ width: '100%' }}>
                                <input placeholder='email' className='brd__none bg__2 brd__r_40 w-70  inp-email'
                                    value={inputEmail}
                                    onChange={(e) => setInputEmail(e.target.value)}
                                />

                                <div className={styles.btn__send}
                                    onClick={handleSendTest}
                                >
                                    <div className='text-6'>{'Отправить'}</div>
                                </div>
                            </div>
                        </>
                        }

                        {
                            email &&
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div className={styles.btn__send}
                                    onClick={handleSendTest}
                                >
                                    <div className='text-6'>{'Отправить результат'}</div>
                                </div>
                            </div>
                        }

                        <div style={{ filter: 'blur(7px)', marginTop: '40px' }}><ResultBlock /></div>

                    </div>
                </div>
            </div>

            
        </div>
    );
}
