

export function Term() {

    return (
        <div style={{ padding: '60px' }}>
            <strong><h3 >Пользовательское Соглашение</h3></strong>
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем <a href="https://checku.online/">https://checku.online/</a> (далее Скоро исполнится 18 лет или Администрация) с одной стороны и пользователем сайта с другой.
            Сайт Скоро исполнится 18 лет не является средством массовой информации.

            Используя сайт, Вы соглашаетесь с условиями данного соглашения.
            Если Вы не согласны с условиями данного соглашения, не используйте сайт Скоро исполнится 18 лет!

            <strong><h5 >Права и обязанности сторон</h5></strong>
            Пользователь имеет право:
            - осуществлять поиск информации на сайте
            - получать информацию на сайте
            - распространять информацию на сайте
            - изменять рейтинг пользователей
            - копировать информацию на другие сайты с указанием источника
            - использовать информацию сайта в личных некоммерческих целях
            - использовать информацию сайта в коммерческих целях с разрешения Администрации
            - использовать информацию сайта в коммерческих целях с разрешения правообладателей
            - использовать информацию сайта в коммерческих целях без специального разрешения

            <h5 ><strong>Администрация имеет право:</strong></h5>
            - по своему усмотрению и необходимости создавать, изменять, отменять правила
            - ограничивать доступ к любой информации на сайте
            - создавать, изменять, удалять информацию
            - удалять учетные записи
            - отказывать в регистрации без объяснения причин

            Пользователь обязуется:
            - обеспечить достоверность предоставляемой информации
            - обеспечивать сохранность личных данных от доступа третьих лиц
            - не нарушать работоспособность сайта
            - не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу
            - не совершать действия, направленные на введение других Пользователей в заблуждение
            - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
            - не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ
            - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами

            <strong><h5 >Администрация обязуется:</h5></strong>
            - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.
            - осуществлять разностороннюю защиту учетной записи Пользователя
            - предоставить всю доступную информацию о Пользователе уполномоченным на то органам государственной власти в случаях, установленных законом

            <strong><h5 >Ответственность сторон</h5></strong>
            - пользователь лично несет полную ответственность за распространяемую им информацию
            - администрация не несет никакой ответственности за достоверность информации, скопированной из других источников
            - администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг
            - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
            - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса

            <strong><h5 >Условия действия Соглашения</h5></strong>
            Данное Соглашение вступает в силу при любом использовании данного сайта.
            Соглашение перестает действовать при появлении его новой версии.
            Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
            Администрация не оповещает пользователей об изменении в Соглашении.

        </div>
    );
}
