import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import { Start } from './components/startTest/start';
import { Test } from './components/Test/test';
import { SendTest } from './components/sendTest/sendTest';
import { Info } from './components/Info/info';
import { Politic } from './components/Common/politic';
import {Term} from './components/Common/term'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            
            {/* <Route
                path="/deal/:deal_id"
                element={
                <Deal/>
                }
            /> */}
            <Route
                path="/"
                element={
                <Start/>
                }
            />
            <Route
                path="/test/:num_test"
                element={
                <Test/>
                }
            />
            <Route
                path="/test"
                element={
                <Test/>
                }
            />
            <Route
                path="/sendtest"
                element={
                <SendTest/>
                }
            />
            <Route
                path="/info"
                element={
                <Info/>
                }
            />
            <Route
                path="/politic"
                element={
                <Politic/>
                }
            />
            <Route
                path="/term"
                element={
                <Term/>
                }
            />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
