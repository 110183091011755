/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import styles from './Test.module.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getApiQuestions, selectCurrentTestId, selectQuestions, selectScore, selectShowCorrect, selectTitleTest, selectUserAnswers, setCurrentTestId, setScore, setUserAnswers } from '../startTest/startSlice';
import { useNavigate, useParams } from 'react-router-dom';

import img_doctor from '../../static/img/doctor.png'
import img_boy_girl from '../../static/img/boy_girl.png'
import parashute from '../../static/img/parashute.png'
import question3 from '../../static/img/question3.png'
import question4 from '../../static/img/question4.png'
import question5 from '../../static/img/question5.png'
import question6 from '../../static/img/question6.png'
import question7 from '../../static/img/question7.png'
import question8 from '../../static/img/question8.png'
import question9 from '../../static/img/question9.png'

import img_boy_girl_m from '../../static/img/boy_girl_m.png'
import parashute_m from '../../static/img/parashute_m.png'
import question3_m from '../../static/img/question3_m.png'
import question4_m from '../../static/img/question4_m.png'
import question5_m from '../../static/img/question5_m.png'
import question6_m from '../../static/img/question6_m.png'
import question7_m from '../../static/img/question7_m.png'
import question8_m from '../../static/img/question8_m.png'
import question9_m from '../../static/img/question9_m.png'

import img_p from '../../static/img/3_3.jpg'

import { svg_bg, svg_bg_mobile } from '../../static/svg/svg';
import { useSelector } from 'react-redux';
import { URL } from '../../static/vars/vars';
// import { Button } from '../Common/button';

const slaids_img = [img_boy_girl, parashute, question3, question4, question5, question6, question7, question8, question9]
const slaids_img_m = [img_boy_girl_m, parashute_m, question3_m, question4_m, question5_m, question6_m, question7_m, question8_m, question9_m]

export function Test() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    let { num_test } = useParams();
    const [numCurrentQuestion, setNumCurrentQuestion] = useState(0);

    const questions = useAppSelector(selectQuestions)
    const titleTest = useAppSelector(selectTitleTest)
    const score = useAppSelector(selectScore)
    const show_correct = useAppSelector(selectShowCorrect)
    const user_answers = useAppSelector(selectUserAnswers)

    const test_id = useSelector(selectCurrentTestId)

    const handleClickAnswer = (e: any) => {
        //console.log(`${numCurrentQuestion + 1}vop`, e.target)
        window.ym(93471245, 'reachGoal', `${numCurrentQuestion + 1}vop`)

        const ind = parseInt(e.target.id)
        //console.log('handleClickAnswer', e.target.id, questions[numCurrentQuestion]?.answers[ind].correct, score)

        // save user answer
        let arr = user_answers.slice()
        arr[numCurrentQuestion] = ind
        dispatch(setUserAnswers(arr))


        if (questions[numCurrentQuestion]?.answers[ind].correct) dispatch(setScore(score + 1))

        if (numCurrentQuestion + 1 === questions.length) navigate('/sendtest', { replace: true })
        else setNumCurrentQuestion(numCurrentQuestion + 1)
    }

    const handleClickBack = () => {
        if (numCurrentQuestion > 0) setNumCurrentQuestion(numCurrentQuestion - 1)
    }

    const handleClickForward = () => {
        if ((numCurrentQuestion + 1) < questions.length) setNumCurrentQuestion(numCurrentQuestion + 1)
    }

    useEffect(() => {
        //console.log('num_test', num_test)
        if (num_test) {
            dispatch(setCurrentTestId(parseInt(num_test)))
            dispatch(getApiQuestions(parseInt(num_test)))
        }
    }, []);

    return (
        <div className={styles.container1}>
            <div className={styles.cntr_svg_bg}>
                {svg_bg_mobile}
            </div>
            <div className={styles.container2}>

                {
                    show_correct &&
                    <div onClick={() => navigate('/', { replace: true })} className='to_main'>На главную</div>
                }
                <div className={styles.title}>{titleTest}</div>

                <div className={styles.cntrRowBetween}>
                    <div className={styles.col1}>
                        {/* <img className='img__col1' src={require(`../../static/img/${test_id}_${numCurrentQuestion + 1}.jpg`)} alt='' /> */}
                        <img className='img__col1' src={URL + '/static/uploads/' + questions[numCurrentQuestion]?.filepath} alt='' />
                        
                    </div>

                    <div className={styles.col2}>
                        <div className={styles.header_col2}>
                            <div className={styles.arrow_container}
                                onClick={handleClickBack}
                            >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 11.8369L3.20605 7L8 2.16315L6.39698 0.5L0 7L6.39698 13.5L8 11.8369Z" fill="#326BFF" />
                                </svg>
                            </div>

                            <div className={styles.text_num_page}>{numCurrentQuestion + 1}/{questions.length}</div>

                            <div className={styles.arrow_container}
                                onClick={handleClickForward}
                            >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 11.8369L4.79395 7L0 2.16315L1.60302 0.5L8 7L1.60302 13.5L0 11.8369Z" fill="#326BFF" />
                                </svg>
                            </div>
                        </div>

                        <div className={styles.question__text}>{questions[numCurrentQuestion]?.text}👩‍❤️‍👨</div>
                        <img className={styles.img__col1_mobile} src={URL + '/static/uploads/' + questions[numCurrentQuestion]?.filepath_m} alt='' />


                        {
                            questions[numCurrentQuestion]?.answers.map((answer: { id: number, text: string, correct: boolean }, index: number) => {
                                return (
                                    <div id={index.toString()} key={index}
                                        className={
                                            (show_correct && answer?.correct) ? styles.btn__answer_correct :
                                                (show_correct && user_answers[numCurrentQuestion] === index) ? styles.btn__answer_wrong :
                                                    styles.btn__answer
                                        }
                                        onClick={!show_correct ? handleClickAnswer : () => { }}
                                    >
                                        {answer?.text}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
